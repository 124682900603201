import { createSlice } from '@reduxjs/toolkit'

const setStep = (step, product, state, action) => {
  state.products[product].steps[step].fields = {
    ...state.products[product].steps[step].fields,
    ...action.payload,
  }
  state.products[product].steps[step].valid = true
}

const initialState = {
  currentStep: 0,
  products: {
    paytester: {
      steps: [
        // 1 - company info
        {
          fields: {
            company_name: '',
            address: '',
            contact_name: '',
            contact_email: '',
            contact_phone: '',
            project_description: '',
          },
          valid: false,
          title: 'Company Information',
        },
        // 1 - Paytester info
        // {
        //   fields: {
        //     paytester_tier: '',
        //     paytester_units_transactions: 0,
        //     paytester_units_devices: 0,
        //     paytester_units_users: 0,
        //     paytester_storage_per_transaction: 0,
        //     paytester_storage_per_device: 0,
        //     paytester_storage_per_user: 0,
        //     //paytester_storage_per_unit: 0,
        //     //paytester_units_type: '',
        //     //paytester_units_number: 0,
        //     deployment_date: new Date(),
        //   },
        //   valid: false,
        //   title: 'Paytester Questions',
        // },
        // 2 - Objectives
        {
          // {
          //     objective: '',
          //     criteria: [
          //       {
          //         criterion: '',
          //         result: '',
          //       },
          //     ],
          //   },
          fields: {
            objectives: [],
          },
          valid: false,
          title: 'Objectives',
        },
        // VPN Info
        {
          fields: {
            device_type: '',
            customer_peer_ip: '',
            customer_lan_ip: '',
            usa_peer_ip: '',
            usa_lan_ip: '',
            auth_method: '',
          },
          valid: false,
          title: 'VPN Information',
        },
        // Channel Info
        {
          fields: {
            channels: [],
            // channel_name: '',
            // message_format: '',
            // record_format: '',
            // communication_protocol: '',
            // max_tps: '',
            // transaction_type: '',
            // cypher_arqc: false,
            // cypher_pin_block: false,
            // cypher_cvv: false,
            // cypher_dinamic_key_change: false,
            // cypher_other: '',
            // channel_connection: [],
          },
          valid: false,
          title: 'Channel Information',
        },
        // Target Environment Info
        {
          fields: {
            target_environments: [],
            // target_environment: '',
            // ip_address: '',
            // max_tps: '',
            // test_types: '',
            // environment_comments: '',
          },
          valid: false,
          title: 'Target Environment',
        },
        // Transactions number
        {
          fields: {
            trans_num: '',
          },
          valid: false,
          title: 'Monthly transactions number',
        },
      ],
    },
    az7: {
      steps: [
        // 2 - AZ7 Info
        {
          fields: {
            az7_tier: '',
            az7_units_transactions: 0,
            az7_units_users: 0,
            az7_storage_per_transaction: 0,
            az7_storage_per_user: 0,
            // az7_units_type: '',
            // az7_units_number: 0,
            az7_requires_ha: false,
          },
          valid: false,
          title: 'AZ7 Questions',
        },
      ],
    },
    ibm: {
      steps: [
        // 1 - Existing IBM Environment
        {
          fields: {
            ibm_goals_eliminate_cost: false,
            ibm_goals_compliance: false,
            ibm_goals_staffing_vacancy: false,
            ibm_goals_performance_problems: false,
            ibm_goals_dc_consolidation: false,
            ibm_goals_other: '',
            ibm_outcomes_security: false,
            ibm_outcomes_availability: false,
            ibm_outcomes_elasticity: false,
            ibm_outcomes_lifecycle_management: false,
            ibm_outcomes_other: '',
            ibm_ha_required: false,
          },
          valid: false,
          title: 'Support for existing IBM environment',
        },
        // 2 - LPARs
        {
          fields: {},
          // {
          //     lpar_os_level: '',
          //     lpar_cpw_requirements: 0,
          //     lpar_cpw_requirements_other: 0,
          //     lpar_memory_requirements: 0,
          //     lpar_memory_requirements_other: 0,
          //     lpar_program_license: '',
          //     lpar_program_license_other: '',
          //   },
          lpars: [],
          valid: false,
          title: 'LPAR Information',
        },
        // 5 - Storage&Backup
        {
          fields: {
            ibm_offsite_backup: false,
            ibm_offsite_backup_retention: '',
            ibm_offsite_backup_retention_other: '',
          },
          valid: false,
          title: 'Storage & Backup',
        },
        // 6 - Connectivity Information
        {
          fields: {
            ibm_wan_connectivity_info_locations: '',
            ibm_wan_connectivity_info_users: '',
            ibm_connectivity_type: '',
          },
          valid: false,
          title: 'Connectivity Information',
        },
        // 7 - Managed Services
        {
          fields: {
            ibm_managed_services_os_ptf: false,
            ibm_managed_services_l2_support: false,
            ibm_managed_services_lpar_security_assessment: false,
          },
          valid: false,
          title: 'Managed Services',
        },
      ],
    },
  },
  steps: [
    // 0 - Project Requirements
    {
      fields: {
        client_project_requirements: '',
      },
      valid: false,
      title: 'Client project requirements',
    },
  ],
}

const wizardSlice = createSlice({
  name: 'wizard',
  initialState: initialState,
  reducers: {
    setCurrentStep(state, action) {
      console.log('wizardSlice::setCurrentStep: state -> ', state, 'action -> ', action)
      state.currentStep = action.payload
      //return {
      //  ...state,
      //  currentStep: action.payload,
      //}
      //state.push(action.payload)
    },
    resetAssessment(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      //Object.assign(state, initialState)
      //state = { ...state, ...initialState }
      return initialState //{}
      //state.push(action.payload)
    },
    setStep1(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      //state[1].fields = { ...state[1].fields, ...action.payload }
      //state[1].valid = true
      setStep(0, state, action)
      //return state
      // return {
      //   ...state,
      //   step1: {
      //     ...state.step1.fields,
      //     ...action.payload,
      //   },
      //   //company_name: action.payload.company_name,
      //   //contact_email: action.payload.contact_email,
      // }
      //state.push(action.payload)
    },
    setGeneralStep1(state, action) {
      state.steps[0].fields = {
        ...state.steps[0].fields,
        ...action.payload,
      }
      state.steps[0].valid = true
    },
    // setStep2(state, action) {
    //   console.log('state -> ', state, 'action -> ', action)
    //   setStep(1, state, action)
    // },
    setAz1Step1(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      setStep(0, 'az7', state, action)
      //setStep(2, state, action)
    },
    setIbmStep1(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      // setStep(3, state, action)
      setStep(0, 'ibm', state, action)
    },
    setIbmStep2(state, action) {
      state.products.ibm.steps[1].lpars.push(action.payload)
      state.products.ibm.steps[1].valid = true
      // console.log('state -> ', state, 'action -> ', action)
    },
    setIbmStep3(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      setStep(2, 'ibm', state, action)
      // setStep(5, state, action)
    },
    setIbmStep4(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      setStep(3, 'ibm', state, action)
      // setStep(6, state, action)
    },
    setIbmStep5(state, action) {
      console.log('state -> ', state, 'action -> ', action)
      setStep(4, 'ibm', state, action)
      // setStep(7, state, action)
    },
    setPaytesterStep1(state, action) {
      setStep(0, 'paytester', state, action)
    },
    setPaytesterStep2(state, action) {
      //console.log('state -> ', state, 'action -> ', action)
      // const obj = {
      //   objective: action.payload.objective,
      //   criteria: action.payload.criteria,
      // }
      //state.products.paytester.steps[1].objectives = []
      state.products.paytester.steps[1].fields.objectives.push({
        objective: action.payload.objective,
        criteria: action.payload.criteria,
      })
      state.products.paytester.steps[1].valid = true
    },
    setPaytesterStep3(state, action) {
      setStep(2, 'paytester', state, action)
    },
    setPaytesterStep4(state, action) {
      state.products.paytester.steps[3].fields.channels.push(action.payload)
      state.products.paytester.steps[3].valid = true
    },
    setPaytesterStep5(state, action) {
      state.products.paytester.steps[4].fields.target_environments.push(action.payload)
      state.products.paytester.steps[4].valid = true
    },
    setPaytesterStep6(state, action) {
      setStep(5, 'paytester', state, action)
    },
  },
})

export { initialState }

export default wizardSlice
