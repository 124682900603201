import { createSlice } from '@reduxjs/toolkit'
import { cpApi } from '../../services/cpService'

import { normalize, schema } from 'normalizr'

//type State = {
//  token: string | null;
//  refreshToken: string | null;
//  account: AccountResponse | null;
//};

//const initialState: State = { token: null, refreshToken: null, account: null };

//const problemSeverity = new schema.Entity('severities')
const supportRequest = new schema.Entity('requests')
const supportSolution = new schema.Entity('solutions', {
  support_requests: [supportRequest],
})

const extractSeverities = (requests) => {
  const severities = {}
  var req = {}
  for (var i = 0; i < requests.length; i++) {
    req = requests[i]
    if (!Object.keys(severities).includes(req.severity)) {
      severities[req.severity] = {
        severityDesc: req.get_severity_display,
        responseTime: req.response_time,
      }
    }
  }

  return severities
}

const supportSlice = createSlice({
  name: 'support',
  initialState: {
    casesPageSize: 10,
    showOpenOnly: 0,
    loading: true,
  },
  reducers: {
    //setOtherStuff(state, action) {
    //  state.param1 = action.payload.param1,
    //  state.param2 = action.payload.param2,
    //},
    setCasesPage(state, action) {
      state.casesPageSize = action.payload
    },
    setCasesFilter(state, action) {
      state.showOpenOnly = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setSolutions(state, action) {
      state.solutions = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(cpApi.endpoints.getSolutions.matchFulfilled, (state, { payload }) => {
      const normalized = normalize(payload, [supportSolution])
      // console.log('!!!!getSolutions.matchFulfilled matcher triggered -> ', normalized.entities)
      state.solutions = normalized.entities
      state.severities = extractSeverities(Object.values(normalized.entities.requests))
    })
  },
})

export default supportSlice
