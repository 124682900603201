import { configureStore, getDefaultMiddleware, Reducer, applyMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { setupListeners } from '@reduxjs/toolkit/query'

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authSlice from './slices/auth'
import supportSlice from './slices/support'
import siteSlice from './slices/site'
import wizardSlice, { initialState } from './slices/wizard'

import { cpApi } from '../services/cpService'

const authInterceptor =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.payload === 'unauthorized') {
      //dispatch(something());
      console.log('authInterceptor: Middleware received Unauthorized response!!!!!!!!!!!!')
    } else {
      next(action)
    }
  }

const rootReducer = combineReducers({
  [cpApi.reducerPath]: cpApi.reducer,
  auth: authSlice.reducer,
  site: siteSlice.reducer,
  wizard: wizardSlice.reducer,
  support: supportSlice.reducer,
})

const proxyReducer = (state, action) => {
  // console.log('proxyReducer -> \n action => ', action, '\n state => ', state)
  if (action.type === 'wizard/resetAssessment') {
    // this applies to all keys defined in persistConfig(s)
    // console.log('proxyReducer => Removing record persist:wizard from the storage')
    storage.removeItem('persist:wizard')
    //storage.setItem('persist:wizard', JSON.stringify(initialState))
    // state = {} as RootState
  }
  return rootReducer(state, action)
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage: storage,
    stateReconciler: autoMergeLevel2,
    // If the api slice reducer is not blacklisted, the api cache will be automatically persisted
    // and restored which could leave you with phantom subscriptions from components that do not exist any more
    blacklist: [cpApi.reducerPath],
  },
  proxyReducer,
  //rootReducer,
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    thunk: {
      extraArgument: {
        customValue: 42,
      },
    },
  }).concat(cpApi.middleware),
  //  .concat(authInterceptor),
})

setupListeners(store.dispatch)

//type RootState = ReturnType<typeof rootReducer>;

const persistor = persistStore(store)

//type RootState = ReturnType<typeof rootReducer>;

export { persistor, store }

//export default store;
