import React from 'react'

import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
//RouteProps
import { useSelector } from 'react-redux'

const Login = React.lazy(() => import('./views/pages/login/Login'))

const ProtectedRoute = (props) => {
  //const { path, ...restProps } = props
  // const auth = useSelector((state) => state.auth)

  const account = useSelector((state) => state.auth.account)
  if (account) {
    if (props.path === '/login') {
      return <Redirect to={'/'} />
    }
    return <Route {...props} />
  } else if (!account) {
    console.log('Redirecting to login page...')
    if (props.path === '/login') {
      return <Route exact path="/login" render={(props) => <Login {...props} />} />
    }
    return <Redirect to={'/login'} />
  } else {
    return <Redirect to={'/404'} />
  }
}

export default ProtectedRoute
